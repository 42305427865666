import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import './WhyBanner.scss';

const WhyBanner: FC<QuizTypes.IWhyBanner> = ({
  whyBanner: [
    {
      properties: { title, link, ariaLink },
    },
  ],
}) => {
  return title ? (
    <div className="why-banner" data-testid="why-banner">
      <Container fluid>
        <div className="why-banner__grid">
          <div className="why-banner__text">
            <DangerouslySetInnerHtml element="h2" className="why-banner__title" html={title} />
          </div>
          <div className="why-banner__cta">
            {link?.length ? (
              <Button ariaLabel={ariaLink} to={link[0].url} variant="pink">
                <DangerouslySetInnerHtml element="span" html={link[0].name} />
                <IconCustom icon="chevron-right" />
              </Button>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};

export default WhyBanner;
