import React, { FC } from 'react';
import './QuizCombinedBottomBar.scss';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

const QuizCombinedBottomBar: FC<QuizTypes.IQuizCombinedBottomBar> = ({ quizResultsBottomBar }) => {
  return (
    <div className="quiz-combined-bottom-bar">
      <DangerouslySetInnerHtml html={quizResultsBottomBar} element="div" />
    </div>
  );
};

export default QuizCombinedBottomBar;
