import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Quiz from 'components/Quiz';
import BreadCrumbs from 'components/common/BreadCrumbs';
import { IPropsQuizPage } from './model';
import './QuizPage.scss';

const QuizPage: FC<IPropsQuizPage> = ({
  data: {
    page: {
      nodes: [
        {
          ariaNext,
          ariaPrev,
          prevButton,
          nextButton,
          ariaResult,
          resultButton,
          switcher,
          progressBar,
          stepPrefix,
          seoMetaTitle,
          seoMetaKeywords,
          seoMetaDescription,
          seoExternalHreflangs,
          openGraphImageUrl,
          progressBarTitle,
          quizSlides,
          quizResult,
          whyBanner,
          recommendedProducts,
          quizDisclaimer,
          showBreadcrumbs,
          pageName,
        },
      ],
    },
    products: { nodes },
    header,
    footer,
    siteSettings,
    commonSettings,
    filteredRecommendedProducts,
  },
  pageContext: { recommendedProductsLinks, breadCrumbs },
}) => {
  const quizRecommendedProductsProps = {
    productLinks: recommendedProductsLinks?.map((url) => ({
      properties: {
        link: [{ url }],
        hideVariant: recommendedProducts?.properties.list?.find(
          (item) => item.properties?.link?.[0].url === url
        )?.properties?.hideVariant,
      },
    })),
    products: filteredRecommendedProducts,
    title: recommendedProducts?.properties.title,
    isFamily: recommendedProducts?.properties.isFamily,
    ariaAllProducts: recommendedProducts?.properties?.ariaAllProducts,
    linkAllProducts: recommendedProducts?.properties?.linkAllProducts,
  };

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <Quiz
        {...{
          ariaNext,
          ariaPrev,
          progressBar,
          stepPrefix,
          progressBarTitle,
          quizSlides,
          prevButton,
          nextButton,
          ariaResult,
          resultButton,
          switcher,
          nodes,
          quizResult,
          whyBanner,
          recommendedProducts: quizRecommendedProductsProps,
          quizDisclaimer,
          pageName,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query QuizQuery($recommendedProductsLinks: [String], $link: String = "", $lang: String = "") {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    filteredRecommendedProducts: allProduct(filter: { link: { in: $recommendedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageFamily {
          ...FragmentGatsbyCardImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    page: allUmbracoQuiz(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        pageName
        ariaNext
        ariaPrev
        prevButton
        nextButton
        ariaResult
        resultButton
        switcher
        quizDisclaimer
        quizResult {
          properties {
            allProducts {
              name
              url
            }
            ariaAllProducts
            ariaRestart
            restart
            resultTitle
            quizResultsBottomBar
          }
        }
        whyBanner {
          properties {
            ariaLink
            link {
              name
              url
            }
            title
          }
        }
        progressBar {
          properties {
            title
          }
        }
        stepPrefix
        seoMetaTitle
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        seoMetaDescription
        progressBarTitle
        showBreadcrumbs
        recommendedProducts {
          properties {
            isFamily
            title
            list {
              properties {
                hideVariant
                link {
                  url
                }
              }
            }
            linkAllProducts {
              name
              url
            }
            ariaAllProducts
          }
        }
        quizSlides {
          content {
            url
            name
            id
            properties {
              disclaimer
              title
              step
              listMode
              prevSlide {
                url
                name
              }
              options {
                properties {
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      ...FragmentSmallImage
                    }
                  }
                  imageAlt
                  subTitle
                  title
                  tags
                  nextSlide {
                    url
                    name
                  }
                }
              }
            }
          }
        }
      }
    }

    products: allProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        tags {
          id
          color {
            label
          }
          isProductFamily
          name
        }
        link
        sku
        cardTitle
        cartFakeLink
        lang
        productImage {
          ...FragmentGatsbyCardImage
        }
        productImageAlt
        tickItems {
          value
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        currentFormat {
          properties {
            label
          }
        }
        category
        flavours {
          properties {
            icon
            label
          }
        }
        sizes {
          properties {
            label
            icon
          }
        }
      }
    }
  }
`;

export default QuizPage;
