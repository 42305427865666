import React, { FC, useEffect } from 'react';

import ProductCard from 'components/ProductСard';
import Button from 'components/common/Button';
import { gtmService } from 'services/gtmService';
import { GUARDIUM } from 'utils/constants';

import QuizCombinedBottomBar from '../QuizCombinedBottomBar';

import './QuizResult.scss';

const QuizResult: FC<QuizTypes.IQuizResult> = ({
  filterResult,
  handleReset,
  quizResult: [
    {
      properties: {
        allProducts,
        ariaAllProducts,
        restart,
        ariaRestart,
        resultTitle,
        quizResultsBottomBar,
      },
    },
  ],
  pageName,
}) => {
  let isGuardium = false;

  filterResult.forEach((product) => {
    if (product.link.includes(GUARDIUM)) {
      isGuardium = true;
    }
  });

  const filterResultFiltered = filterResult.filter((product) => {
    const str = product.link.split('/', 3).slice(2, 3);

    return str[0].includes(GUARDIUM);
  });

  useEffect(() => {
    const firstFilteredProduct = filterResult[0]?.link?.includes(GUARDIUM) ? [] : [filterResult[0]];
    const productsToSend = filterResultFiltered.concat(firstFilteredProduct);

    const timeoutId = gtmService.emitProductListingView(pageName || resultTitle, productsToSend);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [JSON.stringify(filterResult)]);

  return (
    <div className={`quiz-result${isGuardium ? ' combined' : ''}`} data-testid="quiz-result">
      {resultTitle ? <h2 className="quiz-result__title">{resultTitle}</h2> : null}
      <div className="quiz-result__grid">
        {isGuardium ? <QuizCombinedBottomBar quizResultsBottomBar={quizResultsBottomBar} /> : null}

        {filterResultFiltered.map((product) => (
          <ProductCard key={product.cardTitle} {...product} />
        ))}

        {filterResult.map((product, index) =>
          product.link.includes(GUARDIUM) ? null : index === 0 ? (
            <ProductCard key={product.cardTitle} {...product} />
          ) : null
        )}
      </div>

      <div className="quiz-result__nav">
        {restart?.length ? (
          <Button
            classes="quiz-slide__nav-prev"
            ariaLabel={ariaRestart}
            clickHandler={handleReset}
            variant="pink-outline"
          >
            {restart}
          </Button>
        ) : null}
        {allProducts?.length ? (
          <Button
            to={allProducts[0].url}
            classes="quiz-slide__nav-next"
            ariaLabel={ariaAllProducts}
            variant="pink"
          >
            {allProducts[0].name}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default QuizResult;
