import React, { FC } from 'react';
import classnames from 'classnames';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { parseBoolean } from 'utils/parseHelpers';
import './QuizOption.scss';

const QuizOption: FC<QuizTypes.IQuizOption> = ({
  image,
  imageAlt,
  title,
  subTitle,
  handleChange,
  selected,
  idx,
  id,
  listMode,
}) => {
  const optionCSSclassNames = classnames('quiz-option', {
    'quiz-option--active': selected === idx,
    'quiz-option--list-mode': parseBoolean(listMode),
  });

  return (
    <label htmlFor={title + id} className={optionCSSclassNames} data-testid="quiz-option">
      {image?.fallbackUrl ? (
        <div className="quiz-option__image">
          <GatsbyImage
            objectFit="contain"
            className="quiz-option__image-img"
            fluid={image}
            alt={imageAlt}
          />
        </div>
      ) : null}
      <div className="quiz-option__text">
        <strong className="quiz-option__title">{title}</strong>
        {subTitle ? (
          <DangerouslySetInnerHtml className="quiz-option__subtitle" html={subTitle} />
        ) : null}
      </div>
      <input
        className="quiz-option__radio"
        id={title + id}
        value={idx}
        name={String(id)}
        onChange={handleChange}
        checked={selected === idx}
        type="radio"
      />
    </label>
  );
};

export default QuizOption;
